import { useState } from "react";
import { Input, Button } from "@mui/base";

function ContactForm() {
  const [inputValue,setInputValue] = useState({});
  const [submitStatus,setSubmitStatus] = useState(false);
  const onClickSubmitHandler = (e) => {
    e.preventDefault();
    setSubmitStatus(true)
    setTimeout(() => {
      e.target.reset();
      console.log(inputValue);
      setInputValue({});
      setSubmitStatus(false);
    },2000)
    
  }
  const onChangeInputHandler = (e) => {
    setInputValue((prevState) => {
      return {...prevState, [e.target.name] :e.target.value}
    })
  }
  return (
    <form onSubmit={onClickSubmitHandler} className={`w-full rounded border bg-[#1a0f91] flex justify-start items-center flex-col gap-[15px] px-2 md:px-[40px] py-[31px] border-solid border-[black]`}>
      {/* Input Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
      <Input
        slotProps={{
          root: {
            className:
              "w-full border bg-[white] h-[50px] box-border [font-family:Roboto] text-xl font-normal text-[black] flex flex-row items-center [justify-content:start] grow-0 shrink-0 basis-auto pl-[21.5px] border-solid border-[black]",
          },
          input: { className: "w-full bg-transparent [font-family:Roboto] text-xl font-normal [outline:none] box-border border-[none] text-[black]", placeholder: "Your Name", type: "text" },
        }}
        required
        onChange={(e)=>onChangeInputHandler(e)}
        name="name"
      />

      {/* Input Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
      <Input
        slotProps={{
          root: {
            className:
              "w-full border bg-[white] h-[50px] box-border [font-family:Roboto] text-xl font-normal text-[black] flex flex-row items-center [justify-content:start] grow-0 shrink-0 basis-auto pl-[21.5px] border-solid border-[black]",
          },
          input: { className: "w-full bg-transparent [font-family:Roboto] text-xl font-normal [outline:none] box-border border-[none] text-[black]", placeholder: "Your e-mail", type: "text" },
        }}
        required
        onChange={(e)=>onChangeInputHandler(e)}
        name="email"
      />

      {/* Input Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
      <Input
        slotProps={{
          root: {
            className:
              "w-full border bg-[white] h-[50px] box-border [font-family:Roboto] text-xl font-normal text-[black] flex flex-row items-center [justify-content:start] grow-0 shrink-0 basis-auto pl-[21.5px] border-solid border-[black]",
          },
          input: { className: "w-full bg-transparent [font-family:Roboto] text-xl font-normal [outline:none] box-border border-[none] text-[black]", placeholder: "Your Company", type: "text" },
        }}
        required
        onChange={(e)=>onChangeInputHandler(e)}
        name="company"
      />
      <textarea id="message" rows="1"
       className="w-full block p-2.5 dark:placeholder-gray-400 bg-[white] [font-family:Roboto] text-xl font-normal text-[black] grow-0 shrink-0 basis-auto m-0 pt-2.5 pb-[110px] px-[21.5px] p-0" placeholder="Your message..."
       onChange={(e)=>onChangeInputHandler(e)}
        name="message"
       ></textarea>
      <div className={`grow-0 shrink-0 basis-auto mx-[auto] pr-2`}>
        {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
        <Button className={`${submitStatus? "bg-[#8DFF53] w-full px-4": "bg-[white] w-[104px]"} rounded border [font-family:Roboto] text-xl font-medium text-[#1a0f91] h-[50px] cursor-pointer block box-border border-solid border-[black]`}
        type="submit"
        >{submitStatus? "Sent Successfully": "Submit"}</Button>
      </div>
    </form>
  );
}

export default ContactForm;
