import { useCallback, useState, forwardRef } from "react";

function ItConsultingSection() {
  return (
    <div className={`flex justify-center items-stretch flex-col grow-0 shrink-0 basis-auto mt-[45px]`}>
      <p className={`[font-family:Roboto] text-[25px] font-bold text-[#1e1e1e] grow-0 shrink-0 basis-auto m-0 p-0`}>IT Consulting</p>
      <p className={`[font-family:Inter] text-xl font-normal text-left leading-[30px] text-[#1e1e1e] grow-0 shrink-0 basis-auto mt-5 m-0 p-0`}>
        Guiding businesses with strategic IT advice to optimize processes, improve efficiency, and achieve their technology goals.
      </p>
    </div>
  );
}

export default ItConsultingSection;
