import './App.css';
import PageLayout from "./components/PageLayout";

function App() {
  return (
    <div style={{ display: "inline-block", width: "100%" }} data-ignore="used only for top most containter width">
      <PageLayout />
    </div>
  );
}

export default App;
