import { Button } from "@mui/base";
import WebsiteLayout from "../WebsiteLayout";

function HeaderSection() {
  const menuList = ["Home","Services","Our Product","Our Approach"]
  function onClickHeaderItem(sectionId){
    const element = document.getElementById(sectionId);
    if(element){
      element.scrollIntoView({ block: "start",behavior: 'smooth' });
    }
}
  return (
    <div className={`flex justify-start items-stretch flex-col grow-0 shrink-0 basis-auto`}>
      <div className={`shadow-[0px_4px_4px_rgba(0,0,0,0.15)] bg-[white] flex justify-between items-center flex-col md:flex-row gap-2 h-[100px] grow-0 shrink-0 basis-auto box-border md:px-[50px]`}>
        <div className={`bg-[#1e1e1e] flex justify-center items-center flex-col h-[50px] grow-0 shrink-0 w-[100%] sm:w-[auto] basis-auto box-border px-2.5 sm:rounded-[7px]`}>
          <img
            className={`object-cover h-10 max-w-[initial] w-[134px] box-border block grow-0 shrink-0 basis-auto border-[none]`}
            src="images/rep_logo.png" alt="replicacia"
           />
        </div>
        <div className={`flex justify-start items-center flex-row gap-2 sm:gap-[25px] md:gap-[45px] grow-0 shrink-0 basis-auto`}>
          {menuList.map((item) => {
            return (     
              <p className={`[font-family:Roboto] text-sm sm:test-md md:text-xl font-normal text-[black] grow-0 shrink-0 basis-auto m-0 p-0 cursor-pointer`} 
               onClick={() => onClickHeaderItem(item)}
              >{item}</p>
            )
          })}
         {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
          <Button className="hidden lg:block bg-[#1a0f91] [font-family:Roboto] text-xl font-medium text-[white] h-[50px] w-[170px] cursor-pointer box-border grow-0 shrink-0 basis-auto border-[none]"
            onClick={() => onClickHeaderItem("contactus")}
          >
            Contact Us
          </Button>
        </div>
      </div>
      <WebsiteLayout />
    </div>
  );
}

export default HeaderSection;
