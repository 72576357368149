import { Button } from "@mui/base";
import CodeWizardIntro from "../CodeWizardIntro";

function HeroSection() {
  function scrolltoElementHandler(sectionId){
    const element = document.getElementById(sectionId);
    if(element){
      element.scrollIntoView({ block: "start",behavior: 'smooth' });
    }
  }
  return (
    <div id="Our Product" className={`bg-[rgba(219,228,237,0.35)] flex justify-between items-center flex-col lg:flex-row gap-2 self-stretch grow-0 shrink-0 basis-auto md:px-[50px] py-0 md:py-20`}>
      <div className={`flex justify-start items-stretch flex-col grow-0 shrink-1 w-[100%] md:basis-[592px] box-border`}>
        <CodeWizardIntro />
        <div className={`flex justify-center items-center flex-col md:flex-row grow-0 shrink-0 basis-auto mt-[60px] gap-2`}>
          {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
          <Button className="bg-[#1a0f91] [font-family:Roboto] text-xl font-medium text-[white] h-[50px] w-[183px] cursor-pointer block box-border grow-0 shrink-0 basis-auto border-[none]"
           onClick={() => window.open("https://code-wizard.in")}
          >
            Learn More
          </Button>

          {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
          <Button className="border bg-transparent [font-family:Roboto] text-xl font-medium text-[#1a0f91] h-[50px] w-[183px] cursor-pointer block box-border grow-0 shrink-0 basis-auto md:ml-[43px] border-solid border-[#1a0f91]"
           onClick={() => scrolltoElementHandler("contactus")}
          >
            Book a Demo
          </Button>
        </div>
      </div>
      <div className={`grow shrink-0 w-[100%] md:basis-[592px] box-border md:pt-[68px] p-0 md:pb-[69px]`}>
        <div className={`bg-[#d9d9d9] flex justify-center items-center flex-col py-[20px] rounded-[15px]`}>
          <img
            className={`h-[auto] max-w-[initial] w-[90%] block grow-0 shrink-0 basis-auto box-border`} 
            src="images/codelike-wizard.gif" alt="code-wizard reference"  />
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
