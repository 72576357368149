import { Button } from "@mui/base";
import WelcomeSection from "../WelcomeSection";
import ItConsultingSection from "../ItConsultingSection";
import MvpDevelopmentSection from "../MvpDevelopmentSection";
import DevelopmentOverview from "../DevelopmentOverview";
import HeroSection from "../HeroSection";
import ContactSection from "../ContactSection";
import ContactInfo from "../ContactInfo";
import ContactForm from "../ContactForm";

function WebsiteLayout() {
  function scrolltoElementHandler(sectionId){
    const element = document.getElementById(sectionId);
    if(element){
      element.scrollIntoView({ block: "start",behavior: 'smooth' });
    }
  }
  return (
    <div className={`flex justify-start items-center flex-col gap-20 grow-0 shrink-0 basis-auto p-2`}>
      <div id="Home" className={`flex justify-between items-start flex-row gap-2 grow-0 shrink-0 basis-auto w-[100%] md:pl-[50px] relative`} style={{zIndex:20}}>
        <div className={`flex justify-center items-stretch flex-col w-[100%] sm:w-[592px] grow-0 shrink basis-auto box-border py-[20px] md:py-[102px]`}>
          <WelcomeSection />
          <div className={`flex justify-start items-center flex-col md:flex-row grow-0 shrink-0 basis-auto mt-20 gap-2`}>
            <div className={`flex justify-center items-center flex-col w-[100%] sm:w-[31.25%] grow-0 shrink-0 basis-auto box-border`}>
              {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
              <Button className="bg-[#1a0f91] [font-family:Roboto] text-xl font-medium text-[white] h-[50px] w-[170px] cursor-pointer block box-border border-[none]"
               onClick={() => scrolltoElementHandler("Our Product")}
              >Discover More</Button>
            </div>
            <div className={`flex justify-center items-center flex-col w-[100%] sm:w-[50.75%] grow-0 shrink-0 basis-auto box-border md:pl-[21px] md:pr-[34px]`}>
              {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
              <Button className="border bg-[white] [font-family:Roboto] text-xl font-medium text-[#1a0f91] h-[50px] w-[170px] cursor-pointer block box-border border-solid border-[#1a0f91]"
               onClick={() => scrolltoElementHandler("contactus")}
              >
                Contact Us
              </Button>
            </div>
          </div>
        </div>
        <div className={`hidden lg:block grow-0 shrink-0 basis-auto pt-[0.00] absolute right-0`} style={{zIndex:-1}}>
            <img
              className={`h-[646px] max-w-[initial] w-[719px] box-border `}
              src="images/hero_image.png" 
              alt="hero logo"
              />
        </div>
      </div>
      <div id ="Services" className={`flex justify-start items-center flex-col self-stretch grow-0 shrink-0 basis-auto mt-[5px]`}>
        <p className={`[font-family:Roboto] text-[38px] font-medium tracking-[1.00px] text-[black] grow-0 shrink-0 basis-auto m-0 p-0`}>Our Expertise</p>
        <p className={`[font-family:Roboto] text-xl font-normal text-center leading-[30px] text-[black] max-w-[550px] grow-0 shrink-0 basis-auto box-border mt-[30px] m-0 p-0`}>
          Highlighting a range of services that define our commitment to innovation and technology.
        </p>
      </div>
      <div className={`mt-[-15px] flex justify-between items-center flex-col md:flex-row gap-[25px] md:gap-[175px] grow-0 shrink-0 basis-auto px-[20px] md:px-[50px]`}>
        <div className={`flex justify-center items-stretch flex-col max-w-[330px] grow shrink basis-[0.00] box-border pt-[4.75px]`}>
          <div className={`grow-0 shrink-0 basis-auto px-[5px]`}>
            <img
              className={`h-[59px] max-w-[initial] w-[70px] block box-border`}
              src="images/IconService1.svg"
              alt="service1"
            />
          </div>
          <ItConsultingSection />
        </div>
        <div className={`flex justify-center items-stretch flex-col max-w-[330px] grow shrink basis-[0.00] box-border pt-[4.75px]`}>
          <div className={`grow-0 shrink-0 basis-auto px-[5px]`}>
            <img
              className={`h-[61px] max-w-[initial] w-[70px] block box-border`}
              src="images/IconService2.svg"
              alt="service2"
              />
          </div>
          <MvpDevelopmentSection />
        </div>
        <div className={`flex justify-center items-stretch flex-col max-w-[330px] grow shrink basis-[0.00] box-border`}>
          <div className={`grow-0 shrink-0 basis-auto px-[5px]`}>
            <img
              className={`h-[71px] max-w-[initial] w-[70px] block box-border`}
              src="images/IconService3.svg"
              alt="service3"
             />
          </div>
          <DevelopmentOverview />
        </div>
      </div>
      <HeroSection />
      <div className={`flex justify-start items-stretch flex-col md:flex-row self-stretch grow-0 shrink-0 basis-auto md:px-[50px]`}>
        <div className={`hidden lg:block w-[54.63%] grow-0 shrink-0 basis-auto box-border`}>
          <img
            className={`object-cover h-[391px] max-w-[initial] w-[586px] box-border block border-[none]`}
            src="images/Approach Image.png"
            alt="our approach"
            />
        </div>
        <div id="Our Approach" className={`flex justify-center items-stretch flex-col w-[100%] md:w-[45.37%] grow-0 shrink-0 basis-auto box-border pl-[15px] pt-[41px] pb-10`}>
          <p className={`[font-family:Roboto] text-[25px] sm:text-[38px] font-medium tracking-[1.00px] text-left leading-[50px] text-[black] grow-0 shrink-0 basis-auto m-0 p-0`}>
            Innovative Solutions, Client-Centric Approach
          </p>
          <p className={`[font-family:Roboto] text-xl font-normal text-left leading-[30px] text-[black] grow-0 shrink-0 basis-auto mt-[30px] m-0 p-0`}>
            At Replicacia, our approach to problem-solving is rooted in innovation and driven by a client-centric mindset. We believe in crafting solutions that not only meet technical requirements
            but also align seamlessly with our clients&apos; visions. Embracing an agile methodology, we adapt and iterate quickly to deliver high-quality technology solutions that exceed
            expectations.
          </p>
        </div>
      </div>
      <div className={`flex justify-start items-stretch flex-col md:flex-row self-stretch grow-0 shrink-0 basis-auto mt-20 sm:px-[50px] pt-20`}>
        <div className={`flex justify-start items-stretch flex-col w-[100%] md:w-[54.70%] grow-0 shrink-0 basis-auto box-border pb-20`}>
          <ContactSection />
          <div className={`flex justify-start items-stretch flex-col grow-0 shrink-0 basis-auto mt-[70px]`}>
            <p className={`[font-family:Roboto] text-xl font-normal text-[black] grow-0 shrink-0 basis-auto m-0 p-0`}>Or contact us directly at:</p>
            <ContactInfo />
          </div>
        </div>
        <div className={`w-[100%] md:w-[45.30%] grow-0 shrink-0 basis-auto box-border px-[15px]`}>
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default WebsiteLayout;
