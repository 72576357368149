import { useCallback, useState, forwardRef } from "react";
import HeaderSection from "../HeaderSection";

function PageLayout() {
  return (
    <div className={`bg-[white] flex justify-start items-stretch flex-col w-[100%] box-border`}>
      <HeaderSection />
      <div className={`bg-[black] h-[149px] w-[100%] grow-0 shrink-0 basis-auto box-border mt-20`} />
    </div>
  );
}

export default PageLayout;
