function ContactInfo() {
  return (
    <div className={`flex justify-center items-stretch flex-col grow-0 shrink-0 basis-auto mt-3.5`}>
      <div className={`flex justify-start items-start flex-row grow-0 shrink-0 basis-auto`}>
        <img className="w-8 h-8 text-[#1a0f91] flex grow-0 shrink-0 basis-auto"
         src="images/Email_Icon.svg" alt="email"
        />
        <p className={`[font-family:Roboto] text-[22px] font-medium text-[#1a0f91] grow-0 shrink-0 basis-auto ml-[21.5px] m-0 p-0`}>Email: info@replicacia.com</p>
      </div>
      <div className={`flex justify-start items-center flex-row grow-0 shrink-0 basis-auto mt-3.5 px-[4.25px]`}>
        <img className="w-6 h-6 text-[#1a0f91] flex grow-0 shrink-0 basis-auto"
         src="images/Call Icon.svg" alt="call"
        />
        <p className={`[font-family:Roboto] text-[22px] font-medium text-[#1a0f91] grow-0 shrink-0 basis-auto ml-[25.5px] m-0 p-0`}>Mobile: +91-6364 94 2931</p>
      </div>
    </div>
  );
}

export default ContactInfo;
