import { useCallback, useState, forwardRef } from "react";

function CodeWizardIntro() {
  return (
    <div className={`flex justify-start items-stretch flex-col grow-0 shrink-0 basis-auto`}>
      <p className={`[font-family:Roboto] text-[25px] md:text-[38px] font-medium tracking-[1.00px] text-left leading-[50px] text-[black] grow-0 shrink-0 basis-auto m-0 p-0`}>
        Introducing Code-Wizard: Simplifying Code Generation for Developers
      </p>
      <p className={`[font-family:Roboto] text-xl font-normal text-left leading-[30px] text-[black] grow-0 shrink-0 basis-auto mt-[30px] m-0 p-0`}>
        Unlock the true potential of your coding endeavors with Code Wizard, a revolutionary tool designed to redefine your development experience. Seamlessly blending intelligent code generation,
        robust integration capabilities, and effortless deployment, Code Wizard is more than just a tool; it&apos;s your partner in crafting exceptional digital solutions
      </p>
    </div>
  );
}

export default CodeWizardIntro;
