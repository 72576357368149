import { useCallback, useState, forwardRef } from "react";

function ContactSection() {
  return (
    <div id="contactus" className={`grow-0 shrink-0 basis-auto`}>
      <p className={`[font-family:Roboto] text-[25px] sm:text-[38px] font-medium tracking-[1.00px] text-[black] m-0 p-0`}>Get in Touch</p>
      <p className={`[font-family:Roboto] text-xl font-normal text-left leading-[30px] text-[black] max-w-[592px] box-border mt-[30px] m-0 p-0`}>
        We&apos;re eager to connect with you! Whether you have questions about our products, need assistance, or want to explore potential collaborations, we&apos;re here to help. Our dedicated team
        is committed to providing prompt and personalized support.
      </p>
    </div>
  );
}

export default ContactSection;
