import { useCallback, useState, forwardRef } from "react";

function WelcomeSection() {
  return (
    <div className={`flex justify-start items-stretch flex-col grow-0 shrink-0 basis-auto`}>
      <p className={`[font-family:Roboto] text-[25px] md:text-[50px] font-medium tracking-[1.00px] text-left sm:leading-[66px] text-[black] grow-0 shrink-0 basis-auto m-0 p-0`}>
        Welcome to Replicacia: Pioneering the Future of IT Solutions
      </p>
      <p className={`[font-family:Roboto] text-xl font-normal text-left leading-[30px] text-[black] grow-0 shrink-0 basis-auto mt-[30px] m-0 p-0`}>
        We&apos;re Committed to Innovation. Explore Our Vision for a Better Tech Future
      </p>
    </div>
  );
}

export default WelcomeSection;
