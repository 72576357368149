import { useCallback, useState, forwardRef } from "react";

function MvpDevelopmentSection() {
  return (
    <div className={`flex justify-center items-stretch flex-col grow-0 shrink-0 basis-auto mt-[45px]`}>
      <p className={`[font-family:Roboto] text-[25px] font-semibold text-[#1e1e1e] grow-0 shrink-0 basis-auto m-0 p-0`}>MVP Development</p>
      <p className={`[font-family:Inter] text-xl font-normal text-left leading-[30px] text-[#1e1e1e] grow-0 shrink-0 basis-auto mt-5 m-0 p-0`}>
        Crafting Minimum Viable Products (MVPs) to swiftly launch and test new ideas, allowing for rapid iteration and market validation.
      </p>
    </div>
  );
}

export default MvpDevelopmentSection;
